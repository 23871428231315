var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { id: "card-actions" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-card-actions",
                {
                  ref: "cardAction",
                  attrs: { title: "Card Actions" },
                  on: {
                    refresh: function($event) {
                      return _vm.refreshStop("cardAction")
                    }
                  }
                },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("b-table", {
                            attrs: {
                              responsive: "",
                              items: _vm.items,
                              bordered: ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "cell(ICON)",
                                fn: function(data) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "text-center" },
                                      [
                                        _c("feather-icon", {
                                          attrs: { icon: data.value }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-card-actions",
                { attrs: { title: "Collapsible", "action-collapse": "" } },
                [
                  _c("b-card-text", [
                    _c("span", [
                      _vm._v("You can create a collapsible content by adding ")
                    ]),
                    _c("code", [_vm._v("actionCollapse")]),
                    _c("span", [_vm._v(" prop in ")]),
                    _c("code", [_vm._v("<b-card-actions>")])
                  ]),
                  _c(
                    "b-card-text",
                    [
                      _c("span", [_vm._v("Click on ")]),
                      _c("feather-icon", {
                        attrs: { icon: "ChevronDownIcon" }
                      }),
                      _c("span", [_vm._v(" to see card collapse in action.")])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-card-actions",
                {
                  ref: "refreshCard",
                  attrs: { title: "Refresh Content", "action-refresh": "" },
                  on: {
                    refresh: function($event) {
                      return _vm.refreshStop("refreshCard")
                    }
                  }
                },
                [
                  _c("b-card-text", [
                    _vm._v(" To create a card with refresh action use "),
                    _c("code", [_vm._v("actionRefresh")]),
                    _vm._v(" prop along with "),
                    _c("code", [_vm._v("<b-card-actions>")])
                  ]),
                  _c(
                    "b-card-text",
                    [
                      _c("span", [_vm._v("Click on ")]),
                      _c("feather-icon", { attrs: { icon: "RotateCwIcon" } }),
                      _c("span", [
                        _vm._v(" icon to see refresh card content in action.")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "b-card-actions",
                { attrs: { title: "Remove Card", "action-close": "" } },
                [
                  _c("b-card-text", [
                    _vm._v(" You can create a closeable card by using "),
                    _c("code", [_vm._v("actionClose")]),
                    _vm._v(" prop along with "),
                    _c("code", [_vm._v("<b-card-actions>")])
                  ]),
                  _c(
                    "b-card-text",
                    [
                      _c("span", [_vm._v("Click on ")]),
                      _c("feather-icon", { attrs: { icon: "XIcon" } }),
                      _c("span", [
                        _vm._v(" icon to see closeable card in action.")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }